function getUserFromValues(values) {
  if (!values) {
    return {};
  }

  return {
    id: values.id,
    name: values.name,
    surname: values.surname,
    email: values.email,
    password: values.password,
    fiscal: {
      id: values.fiscalId,
      name: values.fiscalName,
      address: values.fiscalAddress,
      cif: values.fiscalCif,
      iva: values.fiscalIva?.slice(0, -1),
      taxes: values.fiscalTaxes?.slice(0, -1),
    },
    store: {
      id: values.storeId,
      name: values.storeName,
      phone: values.storePhone,
      address: values.storeAddress,
      postalCode: values.storePostalCode,
      city: values.storeCity,
      facebook: values.storeFacebook,
      instagram: values.storeInstagram,
      website: values.storeWebsite,
    },
  };
}

export default getUserFromValues;
