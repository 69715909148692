import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const CenterAligned = styled.div`
  text-align: center;
`;

export const ButtonCss = (theme) => css`
  margin: ${theme.padding.unit}px 0;
`;
