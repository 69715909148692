import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import { CHANGE_PASSWORD_ROUTE } from "@routes";

import { CenterAligned, ButtonCss } from "./ProfileContent.style";

import Input from "components/Input";
import Dropdown from "components/Dropdown";
import Button from "components/Button";
import SeparatorBar from "components/SeparatorBar";

const labels = {
  name: "Nombre",
  surname: "Apellidos",
  email: "Dirección de correo eletrónico",
  fiscalName: "Nombre fiscal",
  fiscalAddress: "Domicilio fiscal",
  fiscalCif: "CIF / NIF",
  fiscalIva: "I.V.A.",
  fiscalTaxes: "Recargo de equivalencia",
  storeName: "Nombre tienda",
  storePhone: "Teléfono tienda",
  storeAddress: "Dirección tienda",
  storePostalCode: "Código postal tienda",
  storeCity: "Ciudad tienda",
  storeFacebook: "Facebook tienda (opcional)",
  storeInstagram: "Instagram tienda (opcional)",
  storeWebsite: "Web tienda (opcional)",
};

const FormProfile = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  handleLogout,
}) => (
  <form onSubmit={handleSubmit}>
    <Input
      label={labels.name}
      name="name"
      value={values.name}
      onChange={handleChange}
      onBlur={handleBlur}
      error={errors.name && touched.name && errors.name}
    />
    <Input
      label={labels.surname}
      name="surname"
      value={values.surname}
      onChange={handleChange}
      onBlur={handleBlur}
      error={errors.surname && touched.surname && errors.surname}
    />
    <Input
      disabled
      label={labels.email}
      type="email"
      name="email"
      value={values.email}
      onChange={handleChange}
      onBlur={handleBlur}
      error={errors.email && touched.email && errors.email}
    />
    <SeparatorBar />
    <Input
      label={labels.fiscalName}
      name="fiscalName"
      value={values.fiscalName}
      onChange={handleChange}
      onBlur={handleBlur}
      error={errors.fiscalName && touched.fiscalName && errors.fiscalName}
    />
    <Input
      label={labels.fiscalAddress}
      name="fiscalAddress"
      value={values.fiscalAddress}
      onChange={handleChange}
      onBlur={handleBlur}
      error={
        errors.fiscalAddress && touched.fiscalAddress && errors.fiscalAddress
      }
    />
    <Input
      label={labels.fiscalCif}
      name="fiscalCif"
      value={values.fiscalCif}
      onChange={handleChange}
      onBlur={handleBlur}
      error={errors.fiscalCif && touched.fiscalCif && errors.fiscalCif}
    />
    <Dropdown
      label={labels.fiscalIva}
      name="fiscalIva"
      value={values.fiscalIva}
      onChange={handleChange}
      onBlur={handleBlur}
    >
      {["21%", "0%"]}
    </Dropdown>
    <Dropdown
      label={labels.fiscalTaxes}
      name="fiscalTaxes"
      value={values.fiscalTaxes}
      onChange={handleChange}
      onBlur={handleBlur}
    >
      {["5.20%", "0%"]}
    </Dropdown>
    <SeparatorBar />
    <Input
      label={labels.storeName}
      name="storeName"
      value={values.storeName}
      onChange={handleChange}
      onBlur={handleBlur}
      error={errors.storeName && touched.storeName && errors.storeName}
    />
    <Input
      label={labels.storePhone}
      type="tel"
      name="storePhone"
      value={values.storePhone}
      onChange={handleChange}
      onBlur={handleBlur}
      error={errors.storePhone && touched.storePhone && errors.storePhone}
    />
    <Input
      label={labels.storeAddress}
      name="storeAddress"
      value={values.storeAddress}
      onChange={handleChange}
      onBlur={handleBlur}
      error={errors.storeAddress && touched.storeAddress && errors.storeAddress}
    />
    <Input
      label={labels.storePostalCode}
      name="storePostalCode"
      value={values.storePostalCode}
      onChange={handleChange}
      onBlur={handleBlur}
      error={
        errors.storePostalCode &&
        touched.storePostalCode &&
        errors.storePostalCode
      }
    />
    <Input
      label={labels.storeCity}
      name="storeCity"
      value={values.storeCity}
      onChange={handleChange}
      onBlur={handleBlur}
      error={errors.storeCity && touched.storeCity && errors.storeCity}
    />
    <Input
      label={labels.storeFacebook}
      name="storeFacebook"
      value={values.storeFacebook}
      onChange={handleChange}
      onBlur={handleBlur}
      error={
        errors.storeFacebook && touched.storeFacebook && errors.storeFacebook
      }
    />
    <Input
      label={labels.storeInstagram}
      name="storeInstagram"
      value={values.storeInstagram}
      onChange={handleChange}
      onBlur={handleBlur}
      error={
        errors.storeInstagram && touched.storeInstagram && errors.storeInstagram
      }
    />
    <Input
      label={labels.storeWebsite}
      name="storeWebsite"
      value={values.storeWebsite}
      onChange={handleChange}
      onBlur={handleBlur}
      error={errors.storeWebsite && touched.storeWebsite && errors.storeWebsite}
    />
    <CenterAligned>
      <Link to={CHANGE_PASSWORD_ROUTE}>Cambiar contraseña</Link>
      <Button css={ButtonCss} type="submit">
        Guardar
      </Button>
      <Button
        css={ButtonCss}
        onClick={(event) => {
          event.preventDefault();
          handleLogout();
        }}
      >
        Cerrar sesión
      </Button>
    </CenterAligned>
  </form>
);

FormProfile.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
};

export default FormProfile;
