import React from "react";
import PropTypes from "prop-types";

import { Select } from "./Dropdown.styles";

import Label from "components/Label";

const DropdownWithLabel = ({ label, labelCss, ...dropdownProps }) => {
  if (!label) {
    return <Dropdown {...dropdownProps} />;
  }

  return (
    <Label label={label} css={labelCss}>
      {(label) => <Dropdown {...dropdownProps} id={label} />}
    </Label>
  );
};

DropdownWithLabel.propTypes = {
  label: PropTypes.string,
  labelCss: PropTypes.func,
};

const Dropdown = ({ name, value, onChange, children, ...rest }) => (
  <Select name={name} value={value} onChange={onChange} {...rest}>
    {children.map((child) => (
      <option value={child} key={child}>
        {child}
      </option>
    ))}
  </Select>
);

Dropdown.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default DropdownWithLabel;
