function getValuesFromUser(user) {
  if (!user) {
    return {};
  }

  return {
    id: user.id,
    name: user.name,
    surname: user.surname,
    email: user.email,
    fiscalId: user.fiscal.id,
    fiscalName: user.fiscal.name,
    fiscalAddress: user.fiscal.address,
    fiscalCif: user.fiscal.cif,
    fiscalIva: user.fiscal.iva + "%",
    fiscalTaxes: user.fiscal.taxes + "%",
    storeId: user.store.id,
    storeName: user.store.name,
    storePhone: user.store.phone,
    storeAddress: user.store.address,
    storePostalCode: user.store.postalCode || user.store.postal_code,
    storeCity: user.store.city,
    storeFacebook: user.store.facebook,
    storeInstagram: user.store.instagram,
    storeWebsite: user.store.website,
  };
}

export default getValuesFromUser;
