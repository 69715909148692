import React from "react";
import { Formik } from "formik";

import { updateUser } from "apiClient/users";

import getUserFromValues from "utils/getUserFromValues";
import getValuesFromUser from "utils/getValuesFromUser";
import isValidEmail from "utils/isValidEmail";

import withAuth from "contexts/withAuth";

import PrivateLayout from "layouts/PrivateLayout";
import UserLayout from "layouts/UserLayout";

import ProfileContent from "pagesComponents/Profile/ProfileContent";

const getInitialValues = (filledValues) => ({
  name: "",
  surname: "",
  email: "",
  fiscalName: "",
  fiscalAddress: "",
  fiscalCif: "",
  fiscalIva: "",
  fiscalTaxes: "",
  storeName: "",
  storePhone: "",
  storeAddress: "",
  storePostalCode: "",
  storeCity: "",
  storeFacebook: "",
  storeInstagram: "",
  storeWebsite: "",
  ...filledValues,
});

class ProfilePage extends React.Component {
  state = {
    errors: {},
  };

  validate = (values) => {
    let errors = {};

    if (!isValidEmail(values.email)) {
      errors.email = "Dirección de correo no valida.";
    }

    const requiredFields = [
      "name",
      "surname",
      "email",
      "fiscalName",
      "fiscalAddress",
      "fiscalCif",
      "fiscalIva",
      "fiscalTaxes",
      "storeName",
      "storePhone",
      "storeAddress",
      "storePostalCode",
      "storeCity",
    ];
    const optionalFields = ["storeFacebook", "storeInstagram", "storeWebsite"];
    Object.keys(values).forEach((key) => {
      if (optionalFields.includes(key)) {
        return;
      }

      if (requiredFields.includes(key) && !values[key]) {
        errors[key] = "Requerido";
      }
    });

    return errors;
  };

  onChangeName = (name) => {
    this.setState((state) => {
      const currentErrors = state.errors;
      delete currentErrors[name];

      return { errors: { ...currentErrors } };
    });
  };

  onSubmit = async (values) => {
    try {
      const user = getUserFromValues(values);

      await updateUser(user.id, user);

      this.props.setUser(user);
    } catch (error) {
      console.error(error);
      this.setState({
        saveError: "Ha ocurrido un error, por favor inténtelo de nuevo",
      });
    }
  };

  render() {
    const { user } = this.props;
    const userValues = getValuesFromUser(user);

    return (
      <PrivateLayout layout={UserLayout} title="Perfil de usuario">
        <Formik
          enableReinitialize
          initialValues={getInitialValues(userValues)}
          validate={this.validate}
          onSubmit={this.onSubmit}
        >
          {({ errors, handleChange, ...formikProps }) => (
            <ProfileContent
              {...formikProps}
              errors={{ ...errors, ...this.state.errors }}
              handleChange={(event) => {
                this.onChangeName(event.target.name);
                handleChange(event);
              }}
              handleLogout={this.props.logout}
            />
          )}
        </Formik>
      </PrivateLayout>
    );
  }
}

export default withAuth(ProfilePage);
