import React from "react";
import PropTypes from "prop-types";

import { StyledHr } from "./SeparatorBar.styles";

const SeparatorBar = ({ width }) => {
  return <StyledHr style={{ width }} />;
};

SeparatorBar.propTypes = {
  width: PropTypes.number,
};

export default SeparatorBar;
