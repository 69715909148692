import axios from "axios";

import { prefixApiDomain } from "./utils";

export function fetchUsers() {
  return axios.get(prefixApiDomain("/api/users"));
}

export function fetchUser(email) {
  return axios.get(prefixApiDomain("/api/users"), {
    params: {
      email,
    },
  });
}

export function updateUser(id, user) {
  return axios.put(prefixApiDomain(`/api/users/${id}`), user);
}

export function acceptUser(id) {
  return axios.put(prefixApiDomain(`/api/users/${id}/accept`));
}

export function deleteUser(id) {
  return axios.delete(prefixApiDomain(`/api/users/${id}`));
}

export function resetPasswordUser(email) {
  return axios.post(prefixApiDomain(`/api/password/reset`), {
    email,
  });
}

export function changePasswordUser(email, password) {
  return axios.put(prefixApiDomain(`/api/password/change`), {
    email,
    password,
  });
}
