import styled from "@emotion/styled";

export const StyledHr = styled.hr`
  display: block;
  width: 100%;
  height: 0px;
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};

  margin-bottom: ${(props) => props.theme.padding.unit * 2}px;
`;
